import MathCommand from "@flow-builder/core/src/LogicOperations/Math/MathCommand.ts";
import { LogicOperationsCollection } from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";
import { useLogicStore } from "../../Stores/logic.ts";

export interface MathCommandMediatorContract {
    evaluateMathCommands(payload: LogicOperationsCollection): void;
}

export class MathCommandMediator implements MathCommandMediatorContract {
    protected mathCommands: MathCommand[];
    protected logicStore: ReturnType<typeof useLogicStore>;

    constructor(mathCommandCollection: MathCommand[], logicStore: ReturnType<typeof useLogicStore>) {
        this.mathCommands = mathCommandCollection;
        this.mathCommands.forEach(command => {
            command.initialise();
        });
        this.logicStore = logicStore;
    }

    public evaluateMathCommands(payload: LogicOperationsCollection): void {
        for (const command in this.mathCommands) {
            const result = this.mathCommands[command].injectAndEvaluate(payload);
            if (result === null) // not all required inputs/outputs/variables yet injected
                continue;

            const [key, value] = Object.entries(result.data)[0];
            this.logicStore.setVariableValue(key, value);
            if (result.completed) // remove executeOnce commands
                delete this.mathCommands[command];
        }
    }
}