import {createApp} from "vue";
import {createPinia} from 'pinia';
import './App/Assets/style.css';
import App from './App.vue';
import * as Sentry from "@sentry/browser";

let sentryInitialized = false;
const loadSentry = () => {
    if (sentryInitialized)
        return;

    Sentry.init({
        // dsn: "https://faf5da5fc32a473daf8b99cd33d5a11b@o1134946.ingest.us.sentry.io/6202198",
        dsn: import.meta.env.VITE_SENTRY_DSN,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        // release: "my-project-name@2.3.12",
        release: import.meta.env.VITE_SENTRY_RELEASE,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'local'
    });

    sentryInitialized = true;
}

const mountApp = () => {
    document.querySelectorAll('.flow-client').forEach((parentElement) => {
        const rootProps = { ...(parentElement as HTMLElement).dataset };

        const previewMode = rootProps.previewMode == "1";
        if (!previewMode)
            loadSentry();

        try {
            const app = createApp(App, rootProps);
            app.use(pinia);
            app.mount(parentElement);
        }
        catch (error) {
            console.error("Error mounting Vue app on", parent, ":", error);
        }
    });
}

const pinia = createPinia();

if (document.readyState === "loading")
    document.addEventListener("DOMContentLoaded", () => mountApp());
else
    mountApp();
