import Slide from "@flow-builder/core/src/Slides/Slide.ts";
import {BlockJson} from "@flow-builder/core/src/Blocks/Core/Block.ts";
import {
    MultipleRenderItemBlock,
    RenderCondition,
    RenderConditionJson
} from "@flow-builder/core/src/Blocks/Core/Conditions/RenderCondition.ts";
import {MultipleChoiceInputOption} from "@flow-builder/core/src/Blocks/Core/Inputs/MultipleChoiceInput.ts";
import {SelectedPicture} from "@flow-builder/core/src/Blocks/Core/Inputs/PictureSelect.ts";
import { LogicOperationsCollection } from "@flow-builder/core/src/Blocks/Core/Events/SlideLifecycleEvents.ts";

export const flagBlocksForConditionalRendering = (slide: Slide, currentInputCollection: LogicOperationsCollection) => {
    const buildConditions = (storedConditions: RenderConditionJson[]): RenderCondition[] => {
        return storedConditions.map(condition => new RenderCondition(
            condition.inputTarget,
            condition.modifier,
            condition.operator,
            condition.compareTarget)
        );
    }

    const processBlockItems = (block: BlockJson) => {
        const targetItems = 'pictures' in block
            ? block.pictures
            : 'options' in block
                ? block.options
            : null;
        if (targetItems) {
            (targetItems as MultipleChoiceInputOption[] | SelectedPicture[]).forEach(item => {
                const renderConditions = buildConditions(item.conditions ?? []);
                for (let i = 0; i  < renderConditions.length; i++) {
                    if (!renderConditions[i].isTrue(currentInputCollection)) {
                        item.hideItem = true;
                        break;
                    }
                    item.hideItem = false;
                }
            });
        }
    }

    const processBlocks = (blocks: BlockJson[]) => {
        blocks.forEach(block => {
            if (block.blocks) processBlocks(block.blocks);
            if (block.useConditionalRendering) {
                const renderConditions: RenderCondition[] = buildConditions(block.conditions ?? [])

                for (let i = 0; i  < renderConditions.length; i++) {
                    if (!renderConditions[i].isTrue(currentInputCollection)) {
                        block.hideBlock = true;
                        break;
                    }
                    block.hideBlock = false;
                }

                if (!block.hideBlock && block.type in MultipleRenderItemBlock) {
                    processBlockItems(block);
                }
            }
        });
    }

    //@ts-ignore
    processBlocks(slide.hierarchy)
}