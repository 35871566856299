<template>
    <Transition name="fade" mode="in-out">
        <div v-if="errorStore.activeDevError"
             class="fb-absolute fb-left-[50%] fb-top-[25%] fb-translate-x-[-50%] fb-translate-y-[-50%] fb-w-[46rem] fb-p-4 fb-border fb-rounded fb-backdrop-blur-sm fb-bg-opacity-70 fb-bg-red-50 fb-border-red-700 fb-text-red-800"
        >
            <div class="fb-flex fb-flex-col fb-gap-4 fb-items-center fb-justify-between">
                <p class="fb-text-lg">DEV ERROR</p>
                <p class="fb-text-center fb-leading-5 fb-whitespace-pre-line">
                    {{ errorStore.activeDevError }}
                </p>
                <div class="fb-cursor-pointer hover:fb-text-red-600"
                     @click="errorStore.clearDevError"
                >
                    <svg class="fb-w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useErrorStore } from "../Stores/errors.ts";

const errorStore = useErrorStore();
</script>